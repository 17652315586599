import { isAllUpperCase } from '@/utils/common'
import { AttributeValueTypes, ICredentialAttribute } from './types/credential'
import { capitalCase } from 'change-case'
import { ISchema, SchemaSectionBlock } from './types/schema'
import { consoleError } from '@/utils/console-logger'

export const RESERVED_ATTRIBUTE_KEYS = ['d', 'dt', 'i', 'u']
export const RESERVED_RULE_KEYS = ['d']
export const RESERVED_EDGE_KEYS = ['d']

export type CredentialSectionObject = { [key: string]: any } | null

export function parseSchemaList(schemas: any[]): ISchema[] {
  let parsedSchemas: ISchema[] = []
  for (let i = 0; i < schemas.length; i++) {
    try {
      let schema = parseSchema(schemas[i])
      parsedSchemas.push(schema)
    } catch (ex) {
      consoleError('Error while parsing schema:', ex)
    }
  }
  return parsedSchemas
}

export function parseSchema(schema: any): ISchema {
  const { allAttributes, nonReservedAttributes } = parseSchemaAttributes(schema)
  const edge = parseSchemaEdgeOrRuleSection(schema.properties?.e)
  const rules = parseSchemaEdgeOrRuleSection(schema.properties?.r)

  let hasIssuee = allAttributes.some((attribute) => attribute.key === 'i')

  const hasRootNonce = 'u' in schema.properties
  const isRootNonceRequired =
    hasRootNonce && (schema.required?.includes('u') ?? false)
  const hasAttributeNonce = allAttributes.some(
    (attribute) => attribute.key === 'u'
  )
  const isAttributeNonceRequired = allAttributes.some(
    (attribute) => attribute.key === 'u' && attribute.isRequired === true
  )

  return {
    said: schema.$id,
    title: schema.title,
    description: schema.description,
    credentialType: schema.credentialType,
    version: schema.version,
    attributes: nonReservedAttributes,
    issuee: allAttributes.find((attribute) => attribute.key === 'i'),
    edges: {
      edges: edge,
      isRequired:
        'e' in schema.properties && (schema.required?.includes('e') ?? false)
    },
    rules: {
      rules: rules,
      isRequired:
        'r' in schema.properties && (schema.required?.includes('r') ?? false)
    },
    isUntargetedACDC: hasIssuee === true ? false : true,
    privacy: {
      isPrivateACDC: isRootNonceRequired && isAttributeNonceRequired,
      hasRootNonce,
      isRootNonceRequired,
      hasAttributeNonce,
      isAttributeNonceRequired
    },
    raw: schema
  }
}

/**
 * Parses the schema to extract all attributes and non-reserved attributes.
 *
 * @param schema - The schema object to parse.
 * @returns An object containing allAttributes and nonReservedAttributes arrays.
 */
export function parseSchemaAttributes(schema: any): {
  allAttributes: ICredentialAttribute[]
  nonReservedAttributes: ICredentialAttribute[]
} {
  const allAttributes: ICredentialAttribute[] = []
  const nonReservedAttributes: ICredentialAttribute[] = []

  function createAttribute(
    key: string,
    attr: any,
    isRequired: boolean
  ): ICredentialAttribute {
    let defaultValue: any = ''
    let validationRule: ((value: any) => string | null) | undefined
    let itemType: AttributeValueTypes | undefined
    let itemAttributes: ICredentialAttribute[] | undefined

    switch (attr.type) {
      case 'string':
        defaultValue = ''
        if (attr.format === 'date-time') {
          defaultValue = ''
        }
        if (isRequired) {
          validationRule = (value: any) => {
            if (typeof value !== 'string' || !value.trim())
              return 'credential.attribute.validation.required'
            return null
          }
        }
        break
      case 'boolean':
        defaultValue = false
        if (isRequired) {
          validationRule = (value: any) => {
            if (typeof value !== 'boolean')
              return 'credential.attribute.validation.required'
            return null
          }
        }
        break
      case 'array':
        defaultValue = []
        itemType = attr.items?.type
        // If the array items are objects, process their properties
        if (itemType === 'object' && attr.items?.properties) {
          const nestedAttributes = processProperties(
            attr.items.properties,
            attr.items.required || []
          )
          // Convert the returned object to an array
          itemAttributes = Object.values(nestedAttributes)
        }
        if (isRequired) {
          validationRule = (value: any) => {
            if (!Array.isArray(value) || value.length === 0)
              return 'credential.attribute.validation.required'
            return null
          }
        }
        break
      case 'number':
      case 'integer':
        defaultValue = 0
        if (isRequired) {
          validationRule = (value: any) => {
            if (value === null || value === undefined || isNaN(value))
              return 'credential.attribute.validation.required'
            return null
          }
        }
        break
      case 'object':
        defaultValue = {}
        if (isRequired) {
          validationRule = (value: any) => {
            if (
              typeof value !== 'object' ||
              Array.isArray(value) ||
              value === null
            )
              return 'credential.attribute.validation.required'
            return null
          }
        }
        break
      default:
        defaultValue = ''
        validationRule = undefined
    }

    const humanReadableKey = isAllUpperCase(key) ? key : capitalCase(key)

    const attribute: ICredentialAttribute = {
      key,
      value: defaultValue,
      description: attr.description,
      valueType: attr.type as AttributeValueTypes,
      isRequired: isRequired,
      humanReadableKey,
      validationRule,
      placeholder: attr.placeholder
    }

    // Assign itemType and itemAttributes for arrays
    if (attr.type === 'array') {
      attribute.itemType = itemType
      if (itemAttributes) {
        attribute.itemAttributes = itemAttributes
      }

      if (attr.minItems !== undefined) {
        attribute.minItems = attr.minItems
      }
      if (attr.items?.pattern) {
        attribute.pattern = attr.items.pattern
      }
      if (attr.items?.examples) {
        attribute.examples = attr.items.examples
      }
      if (attr.items?.$comment) {
        attribute.comment = attr.items.$comment
      }
    }

    return attribute
  }

  function processProperties(
    props: any,
    req: string[]
  ): { [key: string]: ICredentialAttribute } {
    const attributes: { [key: string]: ICredentialAttribute } = {}

    for (const key in props) {
      if (props.hasOwnProperty(key)) {
        let attr = props[key]
        let isRequired = req.includes(key)

        // Handle oneOf at any level
        if (attr.oneOf) {
          // Prefer the object type in oneOf
          const objectType = attr.oneOf.find(
            (item: any) => item.type === 'object'
          )
          if (objectType) {
            attr = objectType
          } else {
            // If no object type, skip this attribute
            continue
          }
        }

        const isReserved = RESERVED_ATTRIBUTE_KEYS.includes(key)
        const attribute = createAttribute(key, attr, isRequired)

        // Process nested properties if type is object
        if (attr.type === 'object' && attr.properties) {
          attribute.value = processProperties(
            attr.properties,
            attr.required || []
          )
        }

        // Process nested oneOf within arrays
        if (attr.type === 'array' && attr.items && attr.items.oneOf) {
          // Handle oneOf within array items
          const objectType = attr.items.oneOf.find(
            (item: any) => item.type === 'object'
          )
          if (objectType) {
            const nested = processProperties(
              objectType.properties,
              objectType.required || []
            )
            attribute.itemType = 'object'
            attribute.itemAttributes = Object.values(nested)
          } else {
            // If no object type in oneOf, keep as empty array
            attribute.value = []
          }
        } else if (
          attr.type === 'array' &&
          attr.items &&
          attr.items.type === 'object' &&
          attr.items.properties
        ) {
          // Handle array of objects without oneOf
          const nested = processProperties(
            attr.items.properties,
            attr.items.required || []
          )
          attribute.itemType = 'object'
          attribute.itemAttributes = Object.values(nested)
        }

        attributes[key] = attribute
      }
    }

    return attributes
  }

  const attrObj = schema.properties?.a
  let properties: any = {}
  let required: string[] = []

  if (attrObj?.oneOf) {
    const objectType = attrObj.oneOf.find((item: any) => item.type === 'object')
    if (objectType) {
      properties = objectType.properties
      required = objectType.required || []
    } else {
      return { allAttributes, nonReservedAttributes }
    }
  } else {
    properties = attrObj?.properties || {}
    required = attrObj?.required || []
  }

  const topAttributes = processProperties(properties, required)

  for (const key in topAttributes) {
    if (topAttributes.hasOwnProperty(key)) {
      allAttributes.push(topAttributes[key])
      if (!RESERVED_ATTRIBUTE_KEYS.includes(key)) {
        nonReservedAttributes.push(topAttributes[key])
      }
    }
  }

  return { allAttributes, nonReservedAttributes }
}

export function parseSchemaEdgeOrRuleSection(
  schemaSection: any
): SchemaSectionBlock | undefined | null {
  if (!schemaSection) {
    return null
  }

  if (schemaSection.oneOf) {
    const objectSchema = schemaSection.oneOf.find(
      (item: any) => item.type === 'object'
    )

    if (objectSchema) {
      return parseSchemaBlockProperties(
        objectSchema.properties,
        objectSchema.required
      )
    }
  } else if (schemaSection.properties) {
    return parseSchemaBlockProperties(
      schemaSection.properties,
      schemaSection.required
    )
  } else {
    const properties = schemaSection?.properties
    if (properties) {
      return parseSchemaBlockProperties(properties, schemaSection.required)
    }
    if (
      schemaSection &&
      typeof schemaSection === 'object' &&
      schemaSection.type === 'string'
    ) {
      return schemaSection.hasOwnProperty('const')
        ? { const: schemaSection.const }
        : undefined
    }
  }

  return undefined
}

export const formatAsCredentialEdgeOrRuleObject = (
  schemaSectionObject: SchemaSectionBlock | undefined | null
): CredentialSectionObject => {
  if (
    !schemaSectionObject ||
    (typeof schemaSectionObject === 'object' &&
      Object.keys(schemaSectionObject).length === 0)
  ) {
    return null
  }

  const processBlock = (block: SchemaSectionBlock): any => {
    if (!block.properties) {
      return block.const !== undefined ? block.const : ''
    }

    const result: any = {}

    for (const key in block.properties) {
      if (block.properties.hasOwnProperty(key)) {
        const field = block.properties[key]

        if (field.properties) {
          result[key] = processBlock(field)
        } else {
          result[key] = field.const !== undefined ? field.const : ''
        }
      }
    }

    return result
  }

  const output: any = {}

  if (schemaSectionObject.properties) {
    for (const key in schemaSectionObject.properties) {
      if (schemaSectionObject.properties.hasOwnProperty(key)) {
        const fieldItem = schemaSectionObject.properties[key]
        if (fieldItem && typeof fieldItem === 'object') {
          output[key] = processBlock(fieldItem)
        }
      }
    }
  }

  return output
}

export const getSchemaFieldOfEdge = (edgeObj) => {
  if (!edgeObj) {
    return null
  }

  for (const key in edgeObj) {
    if (edgeObj.hasOwnProperty(key)) {
      if (edgeObj[key].hasOwnProperty('s')) {
        return edgeObj[key]['s']
      }
    }
  }
  return null
}

export const setNodeValueInEdge = (obj, nodeSaid) => {
  if (!obj) {
    return null
  }

  for (const key in obj) {
    if (obj[key].hasOwnProperty('n')) {
      obj[key]['n'] = nodeSaid
    }
  }
  return obj
}

export const convertSchemaAttributeValueType = (
  attribute: ICredentialAttribute
) => {
  let value
  switch (attribute.valueType) {
    case 'string':
      value = String(attribute.value)
      break
    case 'integer':
      value = parseInt(attribute.value, 10)
      break
    case 'boolean':
      value = attribute.value === 'true' || attribute.value === true
      break
    case 'array':
      value = Array.isArray(attribute.value)
        ? attribute.value
        : [attribute.value]
      break
    case 'number':
      value = parseFloat(attribute.value)
      break
    default:
      value = attribute.value
  }
  return value
}

/**
 * Finds the first object type within a oneOf array.
 * @param oneOfArray - The oneOf array from the JSON schema.
 * @returns The object schema if found, otherwise undefined.
 */
const findObjectInOneOf = (oneOfArray: any[]): any | undefined => {
  return oneOfArray.find((item: any) => item.type === 'object')
}

/**
 * Parses a simple schema object field into a SchemaSectionBlock.
 * @param field - The schema field to parse.
 * @returns A SchemaSectionBlock with description, type, and const (if present).
 */
const parseSchemaBlockFields = (field: any): SchemaSectionBlock => {
  return {
    description: field.description,
    type: field.type,
    ...(field.const !== undefined && { const: field.const })
  }
}

/**
 * Recursively parses the properties of the schema section.
 * @param properties - The properties object from the schema.
 * @param requiredFields - An array of required property names.
 * @returns A parsed SchemaSectionBlock with properties, required fields, and descriptions.
 */
const parseSchemaBlockProperties = (
  properties: any,
  requiredFields?: string[]
): SchemaSectionBlock => {
  const parsedProperties: { [key: string]: SchemaSectionBlock } = {}
  const parsedRequired: string[] = requiredFields ? [...requiredFields] : []

  for (const key in properties) {
    if (properties.hasOwnProperty(key)) {
      const field = properties[key]

      if (field.oneOf) {
        const objectInOneOf = findObjectInOneOf(field.oneOf)
        if (objectInOneOf) {
          // Recursively parse the object within oneOf
          const parsedField = parseSchemaBlockProperties(
            objectInOneOf.properties,
            objectInOneOf.required
          )
          // Include description from the object within oneOf
          parsedField.description = objectInOneOf.description
          parsedProperties[key] = parsedField
        } else {
          // If no object type is found in oneOf, parse the first option as a block object
          parsedProperties[key] = parseSchemaBlockFields(field.oneOf[0])
        }
      } else if (field.type === 'object') {
        // Recursively parse nested object properties
        const parsedField = parseSchemaBlockProperties(
          field.properties,
          field.required
        )
        // Include description from the current object field
        parsedField.description = field.description
        parsedProperties[key] = parsedField
      } else {
        // Parse block object schema fields
        parsedProperties[key] = parseSchemaBlockFields(field)
      }
    }
  }

  const result: SchemaSectionBlock = {
    properties: parsedProperties
  }

  if (parsedRequired.length > 0) {
    result.required = parsedRequired
  }

  return result
}

// /// // TODO: to be modified for nested edges, once new ACDC spec is implemented
// // /**
// //  * Finds the first object in the provided edge object that contains the `s` field.
// //  * Returns the value of the schema `s` field along with a reference to the object
// //  * where the node `n` field will be set later.
// //  *
// //  * @param {object} obj - The edge object to search through.
// //  * @returns {{ edgeSchema: string, ref: object } | null} - An object containing the `edgeSchema` and a reference to the object where the `s` field was found.
// //  * Returns `null` if no object with an `s` field is found.
// //  *
// //  * @example
// //  * const schema = {
// //  *   "auth": {
// //  *     "n": "",
// //  *     "s": "said_of_edge_schema",
// //  *   }
// //  * };
// //  * const result = getSchemaFieldValueOfEdge(schema);
// //  * // result.edgeSchema would be "said_of_edge_schema"
// //  * // result.ref would reference the "auth" object
// //  */
// // export const getSchemaFieldOfEdge = (EdgeObj: any): { edgeSchema: string, ref: any } | null => {
// //   for (const key in EdgeObj) {
// //     if (EdgeObj.hasOwnProperty(key)) {
// //       const value = EdgeObj[key]

// //       if (value && typeof value === 'object' && 's' in value) {
// //         return { edgeSchema: value['s'], ref: value }
// //       }
// //     }
// //   }
// //   return null
// // }

// // /**
// //  * Sets the node `n` field of a referenced object of edge block using the provided `nodeSaid`.
// //  * The reference is expected to point to an object that contains the `n` field,
// //  * typically found via the `getSchemaFieldOfEdge` function.
// //  *
// //  * @param {object} ref - The reference to the edge object where the node `n` field should be updated.
// //  * @param {string} nodeSaid - The value to set for the node `n` field.
// //  * @returns {void}
// //  *
// //  * @example
// //  * const ref = {
// //  *   "n": "",
// //  *   "s": "said_of_edge_schema",
// //  * };
// //  * setNodeUsingSchemaFieldRefInEdge(ref, "new_n_value");
// //  * // ref.n is now "new_n_value"
// //  */
// // export const setNodeUsingSchemaFieldRefInEdge = (ref: any, nodeSaid: string) => {
// //   ref['n'] = nodeSaid
// // }
