import { SignifyClient, Operation, Saider, Salter } from 'signify-ts'
import { Notification } from './types'
import { ExnMessageType, KeriEventType } from '@/enums/keri-event-type'
import constants from '@/config/constants'
import { ICredential } from './types/credential'
import { consoleError, consoleLog } from '@/utils/console-logger'
import { getAllNotificationsByType } from './notification'
import DefaultIdentifierIntendedRole from '@/enums/origin-identifier-type'
import { captureException, withScope } from '@sentry/nextjs'
import { RetryException } from '@/exceptions/retry-exception'

export const isEstEvent = (ilk: string): boolean => {
  return (
    ilk === KeriEventType.icp ||
    ilk === KeriEventType.rot ||
    ilk === KeriEventType.dip ||
    ilk === KeriEventType.drt
  )
}

// utility function for making a correctly formatted timestamp
export const createTimestamp = () => {
  return new Date().toISOString().replace('Z', '000+00:00')
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

/**
 * Wait for long running keria operation to become completed
 *
 * Use polling to check the status of the operation every second till max retries are over
 * @async
 * @param {SignifyClient} client Signify Client object
 * @param {Operation} [op] long running keria operation
 * @param {number} [retries] Number of max retries, after which error will be thrown. Defaults to 30
 * @param {number} [delay] Delay in ms between retries, Defaults to 1000
 *
 */
export async function waitOperationWithRetries<T>(
  client: SignifyClient,
  op: Operation<T>,
  maxRetries: number = 30,
  delay: number = 1000
): Promise<Operation<T>> {
  const start = Date.now()
  while (maxRetries-- > 0) {
    op = await client.operations().get(op.name)
    if (op.done === true) return op

    await new Promise((resolve) => setTimeout(resolve, delay))
  }

  const timeSpent = Date.now() - start
  const timeSpentInSecs: number = timeSpent / 1000

  // throw new Error(
  //   `Operation ${op.name} timed out after ${Date.now() - start}ms`
  // )
  throw new RetryException(
    `Wallet operation is not completed after ${timeSpentInSecs} seconds. Operation is ${op.name}`,
    maxRetries,
    maxRetries,
    false,
    true,
    null,
    timeSpent
  )
}

/**
 * Wait for long running keria operation to become completed
 *
 * Use polling to check the status of the operation every second
 * @async
 * @param {SignifyClient} client Signify Client object
 * @param {Operation} [op] long running keria operation
 * @param {number} [timeout] Timeout in ms, after which error will be thrown.  Defaults to 30000
 *
 */
export async function waitOperationWithTimeout<T>(
  client: SignifyClient,
  op: Operation<T>,
  timeout: number = 300000,
  delay: number = 1000
): Promise<Operation<T>> {
  const start = Date.now()
  while (Date.now() - start < timeout) {
    const current = (await client.operations().get(op.name)) as Operation<T>

    if (current.done) {
      return current
    }

    await new Promise((resolve) => setTimeout(resolve, delay))
  }

  throw new Error(
    `Operation ${op.name} timed out after ${Date.now() - start}ms`
  )
}

export function convertLocalToUTC() {
  let localDate = new Date()
  let utcDate = new Date(
    localDate.getTime() + localDate.getTimezoneOffset() * 60000
  )
  return utcDate
}

export function isDifferenceLessThanFiveMinutes(utcDateTime) {
  const utcDate = new Date(utcDateTime)
  const localDate = convertLocalToUTC()
  const diff = Math.abs(utcDate.getTime() - localDate.getTime())
  //check if the difference is less than 10 minutes
  return diff < 600000
}

export const waitForNotification = async (
  client: SignifyClient,
  route: string,
  maxRetries: number = 30,
  delay: number = 1000,
  enableLog: boolean = false
): Promise<Notification | null> => {
  // eslint-disable-next-line no-constant-condition
  if (enableLog)
    consoleLog(`Start polling for notification with route: `, route)
  let retryCount = 0
  let msgSaid = ''
  const notificationTimeRange = 15
  while (msgSaid == '') {
    retryCount = retryCount + 1
    if (enableLog) {
      consoleLog(`Retry count: `, retryCount)
    }

    const timeRangeInMinutes =
      retryCount === maxRetries ? 0 : notificationTimeRange

    let notifications = await getAllNotificationsByType(
      client,
      route,
      timeRangeInMinutes,
      true
    )

    if (enableLog) {
      consoleLog(
        `Filtered notifications of last ${notificationTimeRange} mins: `,
        notifications
      )
    }

    if (notifications.notes.length > 0) {
      for (const notif of notifications.notes) {
        if (enableLog)
          consoleLog(
            `Notification route: ${notif.a.r}, searching for route ${notif.a.r}`
          )
        if (notif.a.r == route && notif.r == false) {
          msgSaid = notif.a.d
          return notif
        }
      }
    }
    if (retryCount >= maxRetries) {
      if (enableLog) consoleLog(`No notification found with route : ${route}`)
      break
    }

    await new Promise((resolve) => setTimeout(resolve, delay))
  }
  return null
}

export const findIssueCredExnMessageForAid = async (
  client: SignifyClient,
  roleRecipientAid: string,
  schemaSaid,
  maxRetries: number = 10,
  delay: number = 1000,
  enableLog: boolean = false
): Promise<{ exn; notification: Notification }> => {
  let route = '/multisig/iss'
  let exn: any = null
  let notification: Notification = null
  let retryCount = 0
  const notificationTimeRange: number = 15
  consoleLog(
    `Find multisig issue credential exn request for aid : `,
    roleRecipientAid
  )

  while (!exn) {
    retryCount = retryCount + 1
    if (enableLog) {
      consoleLog(`Retry count: `, retryCount)
    }

    if (enableLog) {
      consoleLog(`Start fetching recent notifications with route: `, route)
    }

    const timeRangeInMinutes =
      retryCount === maxRetries ? 0 : notificationTimeRange

    //// if its last retry then fetch all notifications
    let notifications = await getAllNotificationsByType(
      client,
      route,
      timeRangeInMinutes,
      true
    )

    if (enableLog)
      consoleLog(
        `Notifications with route ${route} and in last ${notificationTimeRange} mins: `,
        notifications.notes
      )

    if (!notifications || !notifications?.notes) return exn

    if (enableLog)
      consoleLog(
        `Start finding iss exn message in recent notifications of type ${route} to issue credntial to aid ${roleRecipientAid}`
      )
    if (
      schemaSaid == constants.CREDENTIAL_TYPES.OOR_AUTH ||
      schemaSaid == constants.CREDENTIAL_TYPES.ECR_AUTH
    ) {
      let res = await filterAuthCredIssueExnMessage(
        client,
        notifications.notes,
        roleRecipientAid,
        true
      )
      exn = res.exnMessage
      notification = res.notification
    } else if (
      schemaSaid == constants.CREDENTIAL_TYPES.OOR ||
      schemaSaid == constants.CREDENTIAL_TYPES.ECR
    ) {
      let res = await filterRoleCredIssueExnMessage(
        client,
        notifications.notes,
        roleRecipientAid,
        true
      )
      exn = res.exnMessage
      notification = res.notification
    }

    if (retryCount >= maxRetries) {
      if (enableLog) consoleLog(`No notification found with route : ${route}`)
      break
    }

    await new Promise((resolve) => setTimeout(resolve, delay))
  }

  return { exn, notification }
}

export const filterAuthCredIssueExnMessage = async (
  client: SignifyClient,
  notifications: Notification[],
  roleRecipientAid: string,
  enableLog: boolean = false
): Promise<{ exnMessage; notification: Notification }> => {
  let exnMessage: any = null
  let exnNotification: Notification = null

  for (const notification of notifications) {
    try {
      if (enableLog)
        consoleLog(`Fetching group exn request with said : `, notification.a.d)
      let res = await client.groups().getRequest(notification.a.d)
      if (enableLog) consoleLog(`Group exn request: `, res)
      consoleLog(
        `Checking if ${roleRecipientAid} = ${res?.[0]?.exn.e.acdc.a.AID} `
      )
      if (roleRecipientAid == res?.[0]?.exn.e.acdc.a.AID) {
        if (enableLog)
          consoleLog(
            `Found ${notification.a.r} exn message for aid ${roleRecipientAid}  : `,
            notification.a.d
          )

        exnMessage = res?.[0]
        exnNotification = notification
        break
      }
    } catch (ex) {
      consoleError(
        `Error in finding exn message for AID ${roleRecipientAid} `,
        ex
      )
    }
  }
  return { exnMessage, notification: exnNotification }
}

export const filterRoleCredIssueExnMessage = async (
  client: SignifyClient,
  notifications: Notification[],
  roleRecipientAid: string,
  enableLog: boolean = false
): Promise<{ exnMessage; notification: Notification }> => {
  let exnMessage: any = null
  let exnNotification: Notification = null

  for (const notification of notifications) {
    try {
      if (enableLog)
        consoleLog(`Fetching group exn request with said : `, notification.a.d)
      let res = await client.groups().getRequest(notification.a.d)
      if (enableLog) consoleLog(`Group exn request: `, res)
      consoleLog(
        `Checking if ${roleRecipientAid} = ${res?.[0]?.exn.e.acdc.a.i} `
      )
      if (roleRecipientAid == res?.[0]?.exn.e.acdc.a.i) {
        if (enableLog)
          consoleLog(
            `Found multisig/iss exn message for aid ${roleRecipientAid}  : `,
            notification.a.d
          )

        exnMessage = res?.[0]
        exnNotification = notification
        break
      }
    } catch (ex) {
      consoleError(
        `Error in finding exn message for AID ${roleRecipientAid} `,
        ex
      )
    }
  }
  return { exnMessage, notification: exnNotification }
}

export const findGrantCredExnMessageForAid = async (
  client: SignifyClient,
  roleRecipientAid: string,
  schemaSaid: string,
  maxRetries: number = 10,
  delay: number = 1000,
  enableLog: boolean = false
): Promise<{ exn; notification: Notification }> => {
  let route: string = '/exn/ipex/grant'
  let exn: any = null
  let notification: Notification = null
  let retryCount: number = 0
  const notificationTimeRange: number = 15
  consoleLog(
    `Find multisig grant credential exn request for aid : `,
    roleRecipientAid
  )

  while (!exn) {
    retryCount = retryCount + 1

    if (enableLog) {
      consoleLog(`Retry count: `, retryCount)
    }

    if (enableLog) {
      consoleLog(`Start fetching recent notifications with route: `, route)
    }

    const timeRangeInMinutes =
      retryCount === maxRetries ? 0 : notificationTimeRange

    //// if its last retry then fetch all notifications
    let notifications = await getAllNotificationsByType(
      client,
      route,
      timeRangeInMinutes,
      true
    )

    if (enableLog)
      consoleLog(
        `Notifications with route ${route} and in last ${notificationTimeRange} mins: `,
        notifications.notes
      )

    if (!notifications || !notifications?.notes) return exn

    if (enableLog)
      consoleLog(
        `Start finding grant exn message in recent notifications of type ${route} to grant credntial for aid ${roleRecipientAid}`,
        notifications
      )
    if (
      schemaSaid == constants.CREDENTIAL_TYPES.OOR_AUTH ||
      schemaSaid == constants.CREDENTIAL_TYPES.ECR_AUTH
    ) {
      let res = await filterAuthCredGrantExnMessage(
        client,
        notifications.notes,
        roleRecipientAid,
        true
      )
      exn = res.exnMessage
      notification = res.notification
    }

    if (retryCount >= maxRetries) {
      if (enableLog) consoleLog(`No notification found with route : ${route}`)
      break
    }

    await new Promise((resolve) => setTimeout(resolve, delay))
  }

  return { exn, notification }
}

export const filterAuthCredGrantExnMessage = async (
  client: SignifyClient,
  notifications: Notification[],
  roleRecipientAid: string,
  enableLog: boolean = false
): Promise<{ exnMessage; notification: Notification }> => {
  let exnMessage: any = null
  let exnNotification: Notification = null

  for (const notification of notifications) {
    try {
      if (enableLog)
        consoleLog(`Fetching group exn request with said : `, notification.a.d)
      let res = await client.exchanges().get(notification.a.d)
      if (enableLog) consoleLog(`exn request: `, res)
      consoleLog(
        `Checking if ${roleRecipientAid} = ${res.exn?.e?.acdc?.a?.AID} `
      )
      if (roleRecipientAid == res.exn?.e?.acdc?.a?.AID) {
        if (enableLog)
          consoleLog(
            `Found ${notification.a.r} exn message for aid ${roleRecipientAid}  : `,
            notification.a.d
          )

        exnMessage = res
        exnNotification = notification
        break
      }
    } catch (ex) {
      consoleError(
        `Error in finding exn message for AID ${roleRecipientAid} `,
        ex
      )
    }
  }
  return { exnMessage, notification: exnNotification }
}

export const getSchemaName = (
  schemaSAID: string
): { schemaName: string; shortName: string } => {
  let schemaName = ''
  let shortName = ''
  switch (schemaSAID) {
    case constants.CREDENTIAL_TYPES.QVI:
      schemaName = 'Qualified vLEI Issuer Credential'
      shortName = 'QVI'
      break
    case constants.CREDENTIAL_TYPES.LEI:
      schemaName = 'Legal Entity vLEI Credential'
      shortName = 'LE'
      break
    case constants.CREDENTIAL_TYPES.OOR:
      schemaName = 'Official Organizational Role(OOR) Credential'
      shortName = 'OOR'
      break
    case constants.CREDENTIAL_TYPES.ECR:
      schemaName = 'Engagement Context Role(ECR) Credential'
      shortName = 'ECR'
      break
    case constants.CREDENTIAL_TYPES.OOR_AUTH:
      schemaName = 'OOR Authorization Credential'
      shortName = 'OOR Auth'
      break
    case constants.CREDENTIAL_TYPES.ECR_AUTH:
      schemaName = 'ECR Authorization Credential'
      shortName = 'ECR Auth'
      break
    case constants.CREDENTIAL_TYPES.VETTING:
      schemaName = 'Vetting Credential'
      shortName = 'Vetting'
      break
    case constants.CREDENTIAL_TYPES.TN:
      schemaName = 'Telephone Number Credential'
      shortName = 'TN'
      break
    case constants.CREDENTIAL_TYPES.CAMPAIGN:
      schemaName = 'Campaign Credential'
      shortName = 'Campaign'
      break
    case constants.CREDENTIAL_TYPES.XBRL_ATTEST:
      schemaName = 'iXBRL Data Value Attestation'
      shortName = 'iXBRL Attestion'
      break
    case constants.CREDENTIAL_TYPES.XBRL_ATTEST_D6:
      schemaName = 'iXBRL Data Value Attestation'
      break
  }
  return { schemaName, shortName }
}

export const getEdgeSchemaBySchema = (schemaSAID: string): string[] => {
  let edgeSchemaSAID = []
  switch (schemaSAID) {
    case constants.CREDENTIAL_TYPES.LEI:
      edgeSchemaSAID = [constants.CREDENTIAL_TYPES.QVI]
      break
    case constants.CREDENTIAL_TYPES.OOR:
      edgeSchemaSAID = [constants.CREDENTIAL_TYPES.OOR_AUTH]
      break
    case constants.CREDENTIAL_TYPES.ECR:
      edgeSchemaSAID = [constants.CREDENTIAL_TYPES.ECR_AUTH]
      break
    case constants.CREDENTIAL_TYPES.OOR_AUTH:
      edgeSchemaSAID = [constants.CREDENTIAL_TYPES.LEI]
      break
    case constants.CREDENTIAL_TYPES.ECR_AUTH:
      edgeSchemaSAID = [constants.CREDENTIAL_TYPES.LEI]
      break
    case constants.CREDENTIAL_TYPES.VETTING:
      edgeSchemaSAID = [constants.CREDENTIAL_TYPES.LEI]
      break
    case constants.CREDENTIAL_TYPES.TN:
      edgeSchemaSAID = [constants.CREDENTIAL_TYPES.LEI]
      break
    case constants.CREDENTIAL_TYPES.CAMPAIGN:
      edgeSchemaSAID = [constants.CREDENTIAL_TYPES.LEI]
      break
    case constants.CREDENTIAL_TYPES.XBRL_ATTEST:
      edgeSchemaSAID = [
        constants.CREDENTIAL_TYPES.OOR,
        constants.CREDENTIAL_TYPES.ECR
      ]
      break
    case constants.CREDENTIAL_TYPES.XBRL_ATTEST_D6:
      edgeSchemaSAID = [
        constants.CREDENTIAL_TYPES.OOR,
        constants.CREDENTIAL_TYPES.ECR
      ]
      break
  }
  return edgeSchemaSAID
}

export async function filterCredentialBySchema(
  credentials: ICredential[],
  schemaSaid: string
) {
  const cred = credentials.find((cred) => cred.schema === schemaSaid)
  return cred
}

export const getKelEventName = (ilk: string) => {
  if (ilk === KeriEventType.icp) return 'Inception'
  else if (ilk === KeriEventType.ixn) return 'Interaction'
  else if (ilk === KeriEventType.rot) return 'Rotation'
  else if (ilk === KeriEventType.iss) return 'Issue'
  else if (ilk === KeriEventType.dip) return 'Delegated Inception'
  else if (ilk === KeriEventType.drt) return 'Delegated Rotation'
  else if (ilk === KeriEventType.rev) return 'Revocation'
  else if (ilk === KeriEventType.vcp) return 'Registry Inception'
  else if (ilk === KeriEventType.vrt) return 'Registry Rotation'
}

export const getExnMessageName = (type: string) => {
  if (type === ExnMessageType.ICP) return 'Identifier Inception'
  else if (type === ExnMessageType.IXN) return 'Interaction'
  else if (type === ExnMessageType.ROT) return 'Key Rotation'
  else if (type === ExnMessageType.RPY) return 'Endpoint Role Authorization'
  else if (type === ExnMessageType.VCP) return 'Registry Inception'
  else if (type === ExnMessageType.EXN) return 'Credential Exchange'
  else if (type === ExnMessageType.ISS) return 'Credential Issuance'
  else if (type === ExnMessageType.GRANT) return 'Credential Grant'
  else if (type === ExnMessageType.ADMIT) return 'Credential Admit'
}

export const getIdentifierTypeFromAlias = (alias: string, userId: string) => {
  if (alias.includes('as-legal-entity')) {
    return DefaultIdentifierIntendedRole.LE
  }
  if (alias.includes('as-qvi')) {
    return DefaultIdentifierIntendedRole.QVI
  }
  let role: string
  try {
    role = alias.split('as-')[1].split('-at')[0].toLowerCase()
  } catch (err) {
    role = 'unknown'
    withScope((scope) => {
      scope.setContext('GetIdentifierTypeFromAlias', {
        userId: userId,
        message: `Couldn't Fetch the Identifier type from Alias(${alias}) thus setting up as "unknown"`
      })
      scope.setTag('error-type', 'custom')
      scope.setLevel('warning')
      captureException(err)
    })
  }
  switch (role) {
    case 'user':
      return DefaultIdentifierIntendedRole.ORIGIN_USER
    case 'employee':
      return DefaultIdentifierIntendedRole.EMPLOYEE
    case 'qvi':
      return DefaultIdentifierIntendedRole.QVI
    case 'legal-entity':
      return DefaultIdentifierIntendedRole.LE
    default:
      return role
  }
}

/**
 * Saidify an object if 'd' field exists and set 'u' nonce fields.
 *
 * - Sets each 'u' field to `new Salter({}).qb64`.
 * - After setting 'u', checks if 'd' is the first key and its value is ''.
 *   If so, saidify the parent object.
 */
export const saidifyObject = (data: any) => {
  //// Traverses the object to process 'u' and 'd' fields.
  function traverse(
    obj: any,
    parent: any = null,
    parentKey: string | null = null
  ) {
    if (typeof obj !== 'object' || obj === null) return

    const keys = Object.keys(obj)

    for (const key of keys) {
      const value = obj[key]

      if (key === 'u') {
        obj[key] = new Salter({}).qb64

        // Check if 'd' is the first key and its value is ''
        if ('d' in obj && keys[0] === 'd' && obj['d'] === '') {
          const newObj = Saider.saidify(obj)[1]

          if (parent && parentKey) {
            parent[parentKey] = newObj
          }
        }
      } else if (typeof value === 'object' && value !== null) {
        // Recursively traverse nested objects
        traverse(value, obj, key)
      }
    }
  }

  traverse(data)
  return data
}
