import signify, {
  CesrNumber,
  IssueCredentialArgs,
  IssueCredentialResult,
  RevokeCredentialResult,
  Saider,
  Serder,
  SignifyClient
} from 'signify-ts'
import { createTimestamp, waitOperationWithRetries } from './util'
import constants from '@/config/constants'
import useStore from '@/state/store'
import { Notification } from './types'
import {
  autoJoinLEIssuance,
  autoJoinOorAuth,
  autoJoinOorIssuance
} from '@/services/vlei'
import { getGroupExnRequest } from './multisig-aid'
import { getGroupSigningMemberAids, isGroupAid } from '@/utils/aid'
import { getExnMessageBySaid, getRegistries } from './aid'
import { markNotification } from '../signify'
import { parseCredential, parseCredentialList } from './credential-parser'
import { ICredential } from './types/credential'
import { consoleLog } from '@/utils/console-logger'
import { RESERVED_ATTRIBUTE_KEYS } from './schema-parser'

export async function createCredential(
  client: SignifyClient,
  args: IssueCredentialArgs
): Promise<IssueCredentialResult> {
  const result = await client.credentials().issue(args)
  return result
}

/**
 * Issue a credential with a single-sig AID.
 *
 * If recipient is specified, then grant message is sent to recipient
 *
 * @async
 * @param {SignifyClient} client Signify Client object
 * @param {string} [alias] alias of the issuer AID
 * @param {object} [args] Object with fields: registry AID, schema SAID, recipient AID, credential attibutes as per schema, rules(if specified in schema)
 *
 * @returns {Promise<IssueCredentialResult>} A promise to the issue credential result
 */
export async function issueCredential(
  client: SignifyClient,
  args: IssueCredentialArgs
) {
  const result = await client.credentials().issue(args)

  await waitOperationWithRetries(client, result.op)

  const dt = createTimestamp()

  if (args.recipient) {
    const [grant, gsigs, end] = await client.ipex().grant({
      senderName: args.issuerName,
      recipient: args.recipient,
      datetime: dt,
      acdc: result.acdc,
      anc: result.anc,
      iss: result.iss
    })

    await client
      .ipex()
      .submitGrant(args.issuerName, grant, gsigs, end, [args.recipient])
  }

  return result
}

/**
 * Issue a chained credential with a single-sig AID.
 *
 * If recipient is specified, then grant message is sent to recipient
 *
 * @async
 * @param {SignifyClient} client Signify Client object
 * @param {string} [alias] alias of the issuer AID
 * @param {object} [args] Object with fields: registry AID, schema SAID, recipient AID, credential attibutes as per schema, source(credential to be chained) as per schema,
 * rules(if specified in schema)
 *
 * @returns {Promise<IssueCredentialResult>} A promise to the issue credential result
 */
export async function issueChainedCredential(
  client: SignifyClient,
  alias: string,
  args: {
    registry: string
    schema: string
    recipient: string
    data: Record<string, unknown>
    source?: Record<string, unknown>
    rules?: Record<string, unknown>
  }
): Promise<IssueCredentialResult> {
  const result: IssueCredentialResult = await client.credentials().issue({
    issuerName: alias,
    registryId: args.registry,
    schemaId: args.schema,
    recipient: args.recipient,
    data: args.data,
    rules: args.rules && Saider.saidify({ d: '', ...args.rules })[1],
    source: args.source && Saider.saidify({ d: '', ...args.source })[1]
  })

  await waitOperationWithRetries(client, result.op, 300)

  const dt = createTimestamp()

  if (args.recipient) {
    const [grant, gsigs, end] = await client.ipex().grant({
      senderName: alias,
      recipient: args.recipient,
      datetime: dt,
      acdc: result.acdc,
      anc: result.anc,
      iss: result.iss
    })

    await client
      .exchanges()
      .sendFromEvents(alias, 'credential', grant, gsigs, end, [args.recipient])
  }
  return result
}

/**
 * Present a credential with a single-sig AID.
 *
 * @async
 * @param {SignifyClient} client Signify Client object
 * @param {string} [senderName] alias of the sender AID
 * @param {string} [recipientPrefix] prefix of the receiver
 * @param {string} [credentialId] credential SAID
 *
 */
export async function presentCredential(
  client: SignifyClient,
  senderName: string,
  recipientPrefix: string,
  credentialId: string
) {
  const credential = await client.credentials().get(credentialId)

  const dt = createTimestamp()

  const [grant, gsigs, end] = await client.ipex().grant({
    senderName: senderName,
    acdc: new Serder(credential.sad),
    anc: new Serder(credential.anc),
    iss: new Serder(credential.iss),
    recipient: recipientPrefix,
    datetime: dt
  })

  let op = await client
    .ipex()
    .submitGrant(senderName, grant, gsigs, end, [recipientPrefix])

  return await waitOperationWithRetries(client, op)
}

export async function multisigPresentCredential(
  client: SignifyClient,
  groupName: string,
  memberName: string,
  recipientPrefix: string,
  credentialId: string
) {
  const mHab = await client.identifiers().get(memberName)
  const gHab = await client.identifiers().get(groupName)
  const members = await client.identifiers().members(groupName)

  const credential = await client.credentials().get(credentialId)

  const dt = createTimestamp()

  const [grant, gsigs, end] = await client.ipex().grant({
    senderName: groupName,
    recipient: recipientPrefix,
    datetime: dt,
    acdc: new Serder(credential.sad),
    anc: new Serder(credential.anc),
    iss: new Serder(credential.iss)
  })

  await client
    .ipex()
    .submitGrant(groupName, grant, gsigs, end, [recipientPrefix])

  let mstate = gHab['state']
  let sner = new CesrNumber({}, undefined, mstate['ee']['s'])
  let seal = [
    'SealEvent',
    { i: gHab['prefix'], s: sner.num, d: mstate['ee']['d'] }
  ]
  let sigers = gsigs.map((sig: any) => new signify.Siger({ qb64: sig }))
  let ims = signify.d(signify.messagize(grant, sigers, seal))
  let atc = ims.substring(grant.size)
  atc += end
  let gembeds = {
    exn: [grant, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)

  if (recipients.length > 0) {
    consoleLog(
      `Sending join grant credential exn message to other members : `,
      recipients
    )

    await client
      .exchanges()
      .send(
        mHab.name,
        'multisig',
        mHab,
        '/multisig/exn',
        { gid: gHab['prefix'] },
        gembeds,
        recipients
      )
  }
}

export async function grantCredential(
  client: SignifyClient,
  senderName: string,
  recipientPrefix: string,
  credResult: IssueCredentialResult
) {
  const dt = createTimestamp()
  const [grant, gsigs, end] = await client.ipex().grant({
    senderName: senderName,
    recipient: recipientPrefix,
    datetime: dt,
    acdc: credResult.acdc,
    anc: credResult.anc,
    iss: credResult.iss
  })

  await client
    .ipex()
    .submitGrant(senderName, grant, gsigs, end, [recipientPrefix])
}

export async function multisigGrantCredential(
  client: SignifyClient,
  groupName: string,
  memberName: string,
  recipientPrefix: string,
  credResult: IssueCredentialResult,
  datetime?: string
) {
  const mHab = await client.identifiers().get(memberName)
  const gHab = await client.identifiers().get(groupName)
  const members = await client.identifiers().members(groupName)

  // // let dt = credResult.acdc.ked?.a?.dt
  let dt = datetime !== undefined ? datetime : createTimestamp()
  const [grant, gsigs, end] = await client.ipex().grant({
    senderName: groupName,
    recipient: recipientPrefix,
    datetime: dt,
    acdc: credResult.acdc,
    anc: credResult.anc,
    iss: credResult.iss
  })

  await client
    .ipex()
    .submitGrant(groupName, grant, gsigs, end, [recipientPrefix])

  let mstate = gHab['state']
  let sner = new CesrNumber({}, undefined, mstate['ee']['s'])
  let seal = [
    'SealEvent',
    { i: gHab['prefix'], s: sner.num, d: mstate['ee']['d'] }
  ]
  let sigers = gsigs.map((sig: any) => new signify.Siger({ qb64: sig }))
  let ims = signify.d(signify.messagize(grant, sigers, seal))
  let atc = ims.substring(grant.size)
  atc += end
  let gembeds = {
    exn: [grant, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)

  if (recipients.length > 0) {
    consoleLog(
      `Sending join grant credential exn message to other members : `,
      recipients
    )

    await client
      .exchanges()
      .send(
        mHab.name,
        'multisig',
        mHab,
        '/multisig/exn',
        { gid: gHab['prefix'] },
        gembeds,
        recipients
      )
  }
}

export async function admitCredential(
  client: SignifyClient,
  name: string,
  said: string,
  recipient: string
) {
  const dt = createTimestamp()

  const [admit, sigs, end] = await client.ipex().admit(name, '', said, dt)

  await client.ipex().submitAdmit(name, admit, sigs, end, [recipient])
}

export async function multisigAdmitCredential(
  client: SignifyClient,
  groupName: string,
  exnMessageSaid: string
) {
  const gHab = await client.identifiers().get(groupName)
  const mHab = await client.identifiers().get(gHab.group.mhab.name)
  const members = await client.identifiers().members(groupName)
  let exnMessage = await getExnMessageBySaid(client, exnMessageSaid)
  let exn = exnMessage.exn

  let dt = exn?.e?.acdc?.a?.dt ? exn?.e?.acdc?.a?.dt : exn?.dt
  const [admit, sigs, end] = await client.ipex().admit(groupName, '', exn.d, dt)

  await client.ipex().submitAdmit(groupName, admit, sigs, end, [exn.i])

  let mstate = gHab['state']
  let sner = new CesrNumber({}, undefined, mstate['ee']['s'])
  let seal = [
    'SealEvent',
    { i: gHab['prefix'], s: sner.num, d: mstate['ee']['d'] }
  ]
  let sigers = sigs.map((sig: any) => new signify.Siger({ qb64: sig }))
  let ims = signify.d(signify.messagize(admit, sigers, seal))
  let atc = ims.substring(admit.size)
  atc += end
  let gembeds = {
    exn: [admit, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)

  if (recipients.length > 0) {
    consoleLog(
      `Sending join admit credential exn message to other members : `,
      recipients
    )

    await client
      .exchanges()
      .send(
        mHab.name,
        'multisig',
        mHab,
        '/multisig/exn',
        { gid: gHab['prefix'] },
        gembeds,
        recipients
      )
  }
}

export async function sendCredIssueExnMessage(
  client: SignifyClient,
  groupName: string,
  result: IssueCredentialResult
) {
  const groupHab = await client.identifiers().get(groupName)
  const localHab = await client.identifiers().get(groupHab?.mhab?.name)
  const members = await client.identifiers().members(groupName)

  const keeper = client.manager!.get(groupHab)
  const sigs = await keeper.sign(signify.b(result.anc.raw))
  const sigers = sigs.map((sig: string) => new signify.Siger({ qb64: sig }))
  const ims = signify.d(signify.messagize(result.anc, sigers))
  const atc = ims.substring(result.anc.size)

  const embeds = {
    acdc: [result.acdc, ''],
    iss: [result.iss, ''],
    anc: [result.anc, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== localHab.prefix)

  if (recipients.length > 0) {
    consoleLog(
      `Sending join create credential exn message to other members : `,
      recipients
    )
    await client
      .exchanges()
      .send(
        localHab.name,
        'multisig',
        localHab,
        '/multisig/iss',
        { gid: groupHab.prefix },
        embeds,
        recipients
      )
  }
}

export async function handleJoinIssueExnMessage(
  notification: Notification
): Promise<void> {
  let signifyClient = useStore.getState().signifyClient
  let res = await getGroupExnRequest(signifyClient, notification.a.d)
  let exn = res?.[0]?.exn

  if (exn.e.acdc.s === constants.CREDENTIAL_TYPES.QVI) {
  } else if (exn.e.acdc.s === constants.CREDENTIAL_TYPES.LEI) {
    await autoJoinLEIssuance(signifyClient, notification, res?.[0])
  } else if (exn.e.acdc.s === constants.CREDENTIAL_TYPES.OOR) {
    await autoJoinOorIssuance(signifyClient, notification, res?.[0])
  } else if (exn.e.acdc.s === constants.CREDENTIAL_TYPES.OOR_AUTH) {
    await autoJoinOorAuth(signifyClient, notification, res?.[0])
  } else if (exn.e.acdc.s === constants.CREDENTIAL_TYPES.ECR) {
  }
}

export async function handleIpexGrantExnMessage(
  notification: Notification,
  signifyClient: SignifyClient
): Promise<void> {
  //let signifyClient = useStore.getState().signifyClient
  let exnRes = await getExnMessageBySaid(signifyClient, notification.a.d)
  let exn = exnRes.exn

  consoleLog(
    'Auto process exn/ipex/grant exn message and admit credential ',
    exnRes
  )
  const identifiers = await signifyClient.identifiers().list()
  let identifier = identifiers.aids.find((i) => i.prefix == exnRes.exn.a.i)
  if (!identifier) return

  let isGroup = isGroupAid(identifier)
  if (isGroup)
    await multisigAdmitCredential_auto(
      signifyClient,
      identifiers.aids,
      identifier,
      notification,
      exn
    )
  else await admitCredential_auto(signifyClient, identifier, notification, exn)
}

async function admitCredential_auto(
  client: SignifyClient,
  identifier: any,
  notification: Notification,
  exn: any
) {
  consoleLog(
    `Auto admitting exn grant message for single-sig aid ${identifier.name} :`,
    exn.d
  )
  const dt = createTimestamp()

  const [admit, sigs, end] = await client
    .ipex()
    .admit(identifier.name, '', exn.d, dt)

  consoleLog(`Auto admit exn serder :`, admit)
  await client.ipex().submitAdmit(identifier.name, admit, sigs, end, [exn.i])

  await markNotification(client, notification.i)
}

async function multisigAdmitCredential_auto(
  client: SignifyClient,
  identifiers: any[],
  identifier: any,
  notification: Notification,
  exn: any
) {
  consoleLog(
    `Auto admitting exn grant message for multisig aid ${identifier.name} :`,
    exn.d
  )
  let grantSaid: string = exn.d
  let issuerPrefix: string = exn.i
  let groupName = identifier.name

  const members = await client.identifiers().members(groupName)
  let smids = getGroupSigningMemberAids(members)
  const local = identifiers.filter((elem) => {
    return smids.some((x) => {
      return x === elem.prefix
    })
  })

  let mHab = await client.identifiers().get(local?.[0]?.name)
  let gHab = await client.identifiers().get(groupName)

  let dt = exn?.e?.acdc?.a?.dt ? exn?.e?.acdc?.a?.dt : exn?.dt
  const [admit, sigs, end] = await client
    .ipex()
    .admit(groupName, '', grantSaid, dt)

  await client.ipex().submitAdmit(groupName, admit, sigs, end, [issuerPrefix])

  let mstate = gHab['state']
  let sner = new CesrNumber({}, undefined, mstate['ee']['s'])
  let seal = [
    'SealEvent',
    { i: gHab['prefix'], s: sner.num, d: mstate['ee']['d'] }
  ]
  let sigers = sigs.map((sig: any) => new signify.Siger({ qb64: sig }))
  let ims = signify.d(signify.messagize(admit, sigers, seal))
  let atc = ims.substring(admit.size)
  atc += end
  let gembeds = {
    exn: [admit, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)

  await client
    .exchanges()
    .send(
      mHab.name,
      'multisig',
      mHab,
      '/multisig/exn',
      { gid: gHab['prefix'] },
      gembeds,
      recipients
    )

  await markNotification(client, notification.i)
}

export async function getIssuedCredentials(
  client: SignifyClient,
  prefix: string,
  limit: number = 50
): Promise<ICredential[]> {
  let filter = { '-i': prefix }
  let data = await client.credentials().list({ filter: filter, limit: limit })
  let credentials = parseCredentialList(data)
  return credentials
}

export async function getReceivedCredentials(
  client: SignifyClient,
  prefix: string,
  limit: number = 50
): Promise<ICredential[]> {
  let filter = { '-a-i': prefix }
  let credentials = await getAllCredentials(client, filter, limit)
  return credentials
}

export const getAllCredentials = async (
  client: SignifyClient,
  filter: object,
  limit: number = 50
): Promise<ICredential[]> => {
  let creds = null
  if (filter !== undefined && filter !== null)
    creds = await client.credentials().list({ filter: filter, limit: limit })
  else creds = await client.credentials().list({ limit: limit })

  let credentials = parseCredentialList(creds)
  return credentials
}

export async function getCredentialBySaid(
  client: SignifyClient,
  said: string,
  includeCESR: boolean = false
): Promise<ICredential> {
  let data = await client.credentials().get(said, includeCESR)
  let credential = parseCredential(data)
  return credential
}

export async function multisigIssueCredential(
  client: SignifyClient,
  memberName: string,
  groupName: string,
  result: IssueCredentialResult
) {
  const mHab = await client.identifiers().get(memberName)
  const gHab = await client.identifiers().get(groupName)
  const members = await client.identifiers().members(groupName)

  const keeper = client.manager!.get(gHab)
  const sigs = await keeper.sign(signify.b(result.anc.raw))
  const sigers = sigs.map((sig: string) => new signify.Siger({ qb64: sig }))
  const ims = signify.d(signify.messagize(result.anc, sigers))
  const atc = ims.substring(result.anc.size)

  const embeds = {
    acdc: [result.acdc, ''],
    iss: [result.iss, ''],
    anc: [result.anc, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== mHab.prefix)

  await client
    .exchanges()
    .send(
      memberName,
      'multisig',
      mHab,
      '/multisig/iss',
      { gid: gHab.prefix },
      embeds,
      recipients
    )
}

export async function initiateMultisigRevokeCredential(
  client: SignifyClient,
  memberName: string,
  groupName: string,
  credentialSaid: string
) {
  const dt = createTimestamp()
  const result = await client
    .credentials()
    .revoke(groupName, credentialSaid, dt)

  await sendCredRevokeExnMessage(client, memberName, groupName, result)
  return result.op
}

export async function sendCredRevokeExnMessage(
  client: SignifyClient,
  memberName: string,
  groupName: string,
  result: RevokeCredentialResult
) {
  const groupHab = await client.identifiers().get(groupName)
  const localHab = await client.identifiers().get(memberName)
  const members = await client.identifiers().members(groupName)

  const keeper = client.manager!.get(groupHab)
  const sigs = await keeper.sign(signify.b(result.anc.raw))
  const sigers = sigs.map((sig: string) => new signify.Siger({ qb64: sig }))
  const ims = signify.d(signify.messagize(result.anc, sigers))
  const atc = ims.substring(result.anc.size)

  const embeds = {
    iss: [result.rev, ''],
    anc: [result.anc, atc]
  }

  const recipients = members.signing
    .map((m: { aid: string }) => m.aid)
    .filter((aid: string) => aid !== localHab.prefix)

  await client
    .exchanges()
    .send(
      localHab.name,
      'multisig',
      localHab,
      '/multisig/rev',
      { gid: groupHab.prefix },
      embeds,
      recipients
    )
}

export async function handleJoinMultisigRevokeExnMessage(said: string) {
  let signifyClient = useStore.getState().signifyClient
  let exnMessage = await getExnMessageBySaid(signifyClient, said)
  let exn = exnMessage.exn
  let dt = exn.e.iss.dt
  let credentialSaid = exn.e.iss.i

  const identifiers = await signifyClient.identifiers().list()
  let groupIdentifier = identifiers.aids.find((i) => i.prefix == exn.a.gid)
  if (!groupIdentifier) {
    return null
  }
  const groupName = groupIdentifier.name

  const members = await signifyClient.identifiers().members(groupName)

  let smids = getGroupSigningMemberAids(members)
  const memberName = identifiers.aids.filter((elem) => {
    return smids.some((x) => {
      return x === elem.prefix
    })
  })

  const result = await signifyClient
    .credentials()
    .revoke(groupName, credentialSaid, dt)

  await sendCredRevokeExnMessage(
    signifyClient,
    memberName?.[0]?.name,
    groupName,
    result
  )

  return result.op
}

export const joinCreateCredential = async (
  client: SignifyClient,
  notification: Notification,
  exnMessage: any,
  groupName: string,
  memberName: string
) => {
  consoleLog(`Join create credential using grant notification: `, notification)
  consoleLog(`Join create credential exchange message: `, exnMessage)

  let registries = await getRegistries(client, groupName)
  let reg = registries?.[0].regk

  let vcdata = extractCredentialAttributeFromExnMessage(
    exnMessage.exn.e.acdc.s,
    exnMessage
  )

  let credArgs: IssueCredentialArgs = {
    issuerName: groupName,
    registryId: reg,
    schemaId: exnMessage.exn.e.acdc.s,
    recipient: exnMessage.exn.e.acdc.a.i,
    data: vcdata,
    datetime: exnMessage.exn.e.acdc.a.dt,
    ...(exnMessage.exn.e.acdc.r !== null && {
      rules: exnMessage.exn.e.acdc.r
    }),
    ...(exnMessage.exn.e.acdc.e !== null && {
      source: exnMessage.exn.e.acdc.e
    }),
    ...(exnMessage.exn.e.acdc.u && { privacy: true }),
    ...(exnMessage.exn.e.acdc.u && { rootNonce: exnMessage.exn.e.acdc.u }),
    ...(exnMessage.exn.e.acdc.a.u && {
      attributeNonce: exnMessage.exn.e.acdc.a.u
    })
  }

  consoleLog(`Join create credential args: `, credArgs)

  let credResult: IssueCredentialResult = await createCredential(
    client,
    credArgs
  )

  consoleLog(
    `Sending exchange message to other members to join credential creation.`
  )
  await multisigIssueCredential(client, memberName, groupName, credResult)
  return credResult
}

export const extractCredentialAttributeFromExnMessage = (
  schemaId: string,
  exnMessage: any
): any => {
  if (schemaId === constants.CREDENTIAL_TYPES.LEI) {
    const vcdata = {
      LEI: exnMessage.exn.e.acdc.a.LEI
    }
    return vcdata
  } else if (schemaId === constants.CREDENTIAL_TYPES.OOR) {
    const vcdata = {
      LEI: exnMessage.exn.e.acdc.a.LEI,
      personLegalName: exnMessage.exn.e.acdc.a.personLegalName,
      officialRole: exnMessage.exn.e.acdc.a.officialRole
    }
    return vcdata
  } else if (schemaId === constants.CREDENTIAL_TYPES.ECR) {
    const vcdata = {
      LEI: exnMessage.exn.e.acdc.a.LEI,
      personLegalName: exnMessage.exn.e.acdc.a.personLegalName,
      engagementContextRole: exnMessage.exn.e.acdc.a.engagementContextRole
    }
    return vcdata
  } else if (schemaId === constants.CREDENTIAL_TYPES.OOR_AUTH) {
    const vcdata = {
      AID: exnMessage.exn.e.acdc.a.AID,
      LEI: exnMessage.exn.e.acdc.a.LEI,
      personLegalName: exnMessage.exn.e.acdc.a.personLegalName,
      officialRole: exnMessage.exn.e.acdc.a.officialRole
    }
    return vcdata
  } else if (schemaId === constants.CREDENTIAL_TYPES.ECR_AUTH) {
    const vcdata = {
      AID: exnMessage.exn.e.acdc.a.AID,
      LEI: exnMessage.exn.e.acdc.a.LEI,
      personLegalName: exnMessage.exn.e.acdc.a.personLegalName,
      engagementContextRole: exnMessage.exn.e.acdc.a.engagementContextRole
    }
    return vcdata
  } else {
    let vcdata = filterReservedAttributes(
      exnMessage.exn.e.acdc.a,
      RESERVED_ATTRIBUTE_KEYS
    )
    return vcdata
  }
}

export const filterReservedAttributes = (obj, reservedKeys) => {
  const filteredAttributes = {}
  for (const key in obj) {
    if (!reservedKeys.includes(key)) {
      filteredAttributes[key] = obj[key]
    }
  }

  return filteredAttributes
}
