import { hasKey, isAllUpperCase } from '@/utils/common'
import { capitalCase } from 'change-case'
import { getSchemaName } from './util'
import {
  AttributeArrayTypes,
  AttributeBasicTypes,
  AttributeValueTypes,
  IChainCredential,
  ICredential,
  ICredentialAttribute
} from './types/credential'
import constants from '@/config/constants'

export function parseCredential(data: any): ICredential {
  let acdcCredential = null
  if (hasKey(data, 'sad')) {
    acdcCredential = data.sad
  } else if (hasKey(data, 'v') && data['v'].startsWith('ACDC')) {
    acdcCredential = data
  }
  if (!acdcCredential) return null

  let schemaName = getSchemaName(acdcCredential.s).schemaName
  if (!schemaName) {
    if (hasKey(data, 'schema')) {
      schemaName = data.schema?.title || ''
    }
  }

  const credential: ICredential = {
    raw: data,
    version: acdcCredential.v,
    said: acdcCredential.d,
    issuer: acdcCredential.i,
    issuee: acdcCredential.a?.i || null,
    registry: acdcCredential.ri,
    schema: acdcCredential.s,
    schemaTitle: schemaName,
    issuedAt: acdcCredential.a?.dt || '',
    attributes: [],
    edge: {
      type: undefined,
      said: undefined,
      schema: undefined
    }
  }

  credential.attributes = parseAttributes(acdcCredential.a)

  credential.rules = acdcCredential.r

  if (acdcCredential.e) {
    const edgeKeys = Object.keys(acdcCredential.e)
    for (const key of edgeKeys) {
      if (acdcCredential.e[key] === 'd') continue
      if (
        typeof acdcCredential.e[key] === 'object' &&
        acdcCredential.e[key]?.s
      ) {
        let edge = {
          type: key,
          said: acdcCredential.e[key]?.n || '',
          schema: acdcCredential.e[key]?.s || ''
        }
        credential.edge = edge
        break
      }
    }
  }

  if (credential.schema === constants.CREDENTIAL_TYPES.QVI) {
    credential.chain = {
      isRootOfTrust: true,
      issuer: credential.issuer
    } as IChainCredential
  } else if (hasKey(data, 'chains')) {
    if (Array.isArray(data['chains']) && data['chains'].length > 0) {
      credential.chain = parseChain(data['chains'])
    }
  }

  return credential
}

export function parseCredentialList(data: any[]): ICredential[] {
  let credentials: ICredential[] = []
  if (data && data?.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const credential = parseCredential(data[i])
      credentials.push(credential)
    }
    try {
      credentials.sort(
        (a, b) =>
          new Date(b.issuedAt).getTime() - new Date(a.issuedAt).getTime()
      )
    } catch (ex) {
      console.error('Error while sorting credentials', ex)
    }
  }
  return credentials
}

export function parseChain(chains: any): IChainCredential {
  if (chains?.length === 0) {
    return null
  }

  let sad = null
  let chainCred = chains[0]
  if (hasKey(chainCred, 'sad')) {
    sad = chainCred.sad
  }

  if (!sad) return null

  const credential: IChainCredential = {
    raw: null,
    version: sad.v,
    said: sad.d,
    issuer: sad.i,
    issuee: sad.a?.i || null,
    registry: sad.ri,
    schema: sad.s,
    schemaTitle: getSchemaName(sad.s).schemaName,
    issuedAt: sad.a?.dt || '',
    attributes: []
  }

  let attributes = parseAttributes(sad.a)
  credential.attributes = attributes

  if (credential.schema === constants.CREDENTIAL_TYPES.QVI) {
    credential.chain = {
      isRootOfTrust: true,
      issuer: credential.issuer
    } as IChainCredential
  } else if (hasKey(chainCred, 'chains')) {
    if (Array.isArray(chainCred['chains']) && chainCred['chains'].length > 0) {
      credential.chain = parseChain(chainCred['chains'])
    }
  }

  return credential
}

export function parseAttributes(
  attrs: Record<string, any>
): ICredentialAttribute[] {
  const attibutes: ICredentialAttribute[] = []
  const excludedKeys = ['d', 'dt', 'i', 'u']

  Object.entries(attrs).forEach(([key, value]) => {
    if (!excludedKeys.includes(key)) {
      let valueType: AttributeValueTypes
      if (value === null) {
        valueType = 'null'
      } else if (value === undefined) {
        valueType = 'undefined'
      } else if (Array.isArray(value)) {
        valueType = determineArrayType(value)
      } else if (typeof value === 'object') {
        valueType = 'object'
      } else {
        valueType = typeof value as AttributeBasicTypes
      }

      const humanReadableKey =
        isAllUpperCase(key) === true ? key : capitalCase(key)

      attibutes.push({ key, value, valueType, humanReadableKey })
    }
  })

  return attibutes
}

export function excludeReservedAttributes(
  fields: Record<string, any>
): Record<string, any> {
  const excludedKeys = ['d', 'dt', 'i', 'u']
  const attibutes: Record<string, any> = {}

  Object.entries(fields).forEach(([key, value]) => {
    if (!excludedKeys.includes(key)) {
      attibutes[key] = value
    }
  })

  return attibutes
}

function determineArrayType(arr: any[]): AttributeArrayTypes {
  if (arr.length === 0) {
    return 'empty'
  }

  const isStringArray = arr.every((item) => typeof item === 'string')
  return isStringArray ? 'string[]' : 'object[]'
}
