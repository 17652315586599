export enum CredentialStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum CredentialType {
  ISSUED = 'issued',
  RECEIVED = 'received'
}

export enum FETCHING_STATUS {
  NOT_INITIATED = 'NOT_INITIATED',
  FETCHING = 'FETCHING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED'
}

export enum PHOTO_LOADING_STATUS {
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED'
}

export enum CEREMONY_TYPE {
  VLEI = 'VLEI',
  OOR = 'OOR',
  ECR = 'ECR'
}

type APP_PROFILE = 'development' | 'production'

export default {
  PROFILE: (process.env.NODE_ENV || 'production') as APP_PROFILE,
  PUBLIC_API_ENDPOINT: process.env.NEXT_PUBLIC_API_ENDPOINT,
  AWS_CLIENT_ID: process.env.NEXT_PUBLIC_AWS_APP_CLIENT_ID as string,
  AWS_CLIENT_POOL_ID: process.env.NEXT_PUBLIC_AWS_APP_USERPOOL_ID as string,
  AUTH_URL: process.env.NEXT_PUBLIC_USER_MGMT_URL,
  KERIA_URL: process.env.NEXT_PUBLIC_KERIA_URL as string,
  ID_VERIFICATION_URL: process.env.NEXT_PUBLIC_ID_VERIFICATION_URL as string,
  WEBMEET_URL: process.env.NEXT_PUBLIC_WEBMEET_URL as string,
  WITNESS_AIDS: process.env.NEXT_PUBLIC_WITNESS_AIDS as string,
  WORKFLOW_URL: process.env.NEXT_PUBLIC_WORKFLOW_URL as string,
  ORGANIZATION_URL: process.env.NEXT_PUBLIC_ORG_URL as string,
  APPT_COORD_URL: process.env.NEXT_PUBLIC_APPT_COORD_URL as string,
  NOTIFICATION_URL: process.env.NEXT_PUBLIC_NOTIFICATION_URL as string,
  I10N_URL: process.env.NEXT_PUBLIC_I10N_URL as string,
  SIGNIFY_BOOT: process.env.NEXT_PUBLIC_SIGNIFY_BOOT as string,
  VDOC_SVC_URL: process.env.NEXT_PUBLIC_VDOC_URL as string,
  CAXE_SVC_URL: process.env.NEXT_PUBLIC_CAXE_URL as string,
  CESR_VERIFIER_URL: process.env.NEXT_PUBLIC_CESR_VERIFIER_URL as string,
  TELEPHONE_VALIDATION_PATTERN: /^\+(?:[0-9] ?){6,14}[0-9]$/, //  E.164 format
  DATE_FORMAT: 'MM-dd-yyyy',
  DATE_TIME_FORMAT: 'MM-dd-yyyy hh:mm:ss a',
  DATE_TIME_TZ_FORMAT: 'MM-dd-yyyy hh:mm:ss a zzz',
  MEET_DATE_FORMAT: 'EEEE, MMMM dd',
  MEET_TIME_FORMAT: 'h:mm aaa',
  PASS_CODE_LIFETIME: 7200000, //  5 Min, 300000 milliseconds
  PASS_CODE_LIFETIME_EXTENDED: 7200000, //  2 Hours, 7200000 milliseconds, this is for ceremony only & must not be less than ceremony duration
  TOAST_DISPLAY_TIME: 5000,
  LEI_NUMBER_LENGTH: 20,
  CREDENTIAL_TYPES: {
    VETTING: 'EPy_7LE3tVdl8qEKN5i4L8eAgIM-1I51-DNiewmcq-fe',
    TN: 'ELoARGqkPoR6pkpHSYYXlppd522_N-C_P_XSVz23hyjW',
    CAMPAIGN: 'EF-2niGwOfhDSb0qQBLhmHtY8z0cYVy4kCDHC9c8r0ME',
    LEI: 'ENPXp1vQzRF6JwIuS-mp2U8Uf1MoADoP_GqQ62VsDZWY',
    QVI: 'EBfdlu8R27Fbx-ehrqwImnK-8Cm79sqbAQ4MmvEAYqao',
    OOR: 'EBNaNu-M9P5cgrnfl2Fvymy4E_jvxxyjb70PRtiANlJy',
    OOR_AUTH: 'EKA57bKBKxr_kN7iN5i7lMUxpMG-s19dRcmov1iDxz-E',
    ECR: 'EEy9PkikFcANV1l7EHukCeXqrzT1hNZjGlUk7wuMO5jw',
    ECR_AUTH: 'EH6ekLjSr8V32WyFbGe1zXjTzFs9PkTYmupJ9H65O14g',
    XBRL_ATTEST: 'EMhvwOlyEJ9kN4PrwCpr9Jsv7TxPhiYveZ0oP3lJzdEi',
    XBRL_ATTEST_D6: 'ECYorXkheU7YsXZkYLGtvBOxEZ6alS5H5FJRn0tgDXV0'
  },
  CREDENTIAL_ATTRIBUTES: {
    OFFICIAL_ROLE: 'officialRole'
  },
  ceremonyTypes: {
    VLEI: 'VLEI',
    OOR: 'OOR',
    ECR: 'ECR'
  },
  ceremonySteps: {
    LE: 6,
    OOR: 7,
    ECR: 6
  },
  AID_NAMES: [
    {
      name: 'Gleif',
      aid: 'EHmR-f9oRqtJEkIkxzClhjc84Aqbp5dylK1Ugn8bEt7Y'
    },
    {
      name: 'provenant-qvi',
      aid: 'ECVKeYAbQkmCLy4gnr8suhQzYOykvxDrVANZhE4Fp-iq'
    },
    {
      name: 'provenant-vetter',
      aid: 'ELaFLtjX-v1-tpTzIfMAFEhCMepcfYjP9_CHyl53B7ZC'
    },
    {
      name: 'Syniverse',
      aid: 'EEkzBv05zN33PdaTlkw97D2haVj2WPFVlKymPhcjYCT9'
    },
    {
      name: 'subway',
      aid: 'EJOnVfxUKTtx2Ol_seuRfcBNu0rvrIZN4q7InQ3E1OeV'
    }
  ],
  ONBOARDING: {
    KEY_ORG: 'ORG'
  },
  WORKFLOW_MODEL_IDS: {
    LE: 'create-le-vlei-prerequisites',
    OOR: 'create-oor-vlei-prerequisites',
    ECR: 'create-ecr-vlei-prerequisites'
  },
  WORKFLOW_NODE_COUNT: 7,
  WORKFLOW_NODE_STATUS: {
    IN_PROGRESS: 'IN_PROGRESS',
    ASSIGNED: 'ASSIGNED',
    COMPLETE: 'COMPLETE',
    FAILED: 'FAILED',
    NOT_ASSIGNED: 'NOT_ASSIGNED'
  },
  ONETIME_WORKFLOW: {
    GET_STARTED: 'get-started'
  },
  USER_INTERACTION: {
    USER_INFO: '2. Provide Input',
    SETUP_AIDS: '3. Setup AIDs'
  },
  ROLES: {
    DAR: 'DAR',
    LAR: 'LAR',
    QAR: 'QAR',
    OOR: 'OOR',
    ECR: 'ECR',
    EMPLOYEE: 'EMPLOYEE'
  },
  JITSI_LIB: process.env.NEXT_PUBLIC_JITSI_LIB,
  JITSI_CONFIG: {
    hosts: {
      domain: process.env.NEXT_PUBLIC_JITSI_DOMAIN,
      muc: process.env.NEXT_PUBLIC_JITSI_MUC
    },
    serviceUrl: process.env.NEXT_PUBLIC_JITSI_SVC_URL
  },
  JITSI_STUN_SERVERS: {
    stunServers: process.env.NEXT_PUBLIC_JITSI_STUN_SERVERS
      ? process.env.NEXT_PUBLIC_JITSI_STUN_SERVERS.split(',').map((urls) => ({
          urls
        }))
      : []
  },
  NOTIFICATION_POLL_INTERVAL:
    parseInt(process.env.NEXT_PUBLIC_NOTIFICATION_POLL_INTERVAL) ?? 300000,
  DEVELOPER_SETTINGS: {
    hideByDefault:
      process.env.NEXT_PUBLIC_HIDE_DEVELOPER_SETTINGS_BY_DEFAULT ?? false,
    showOnClickCount:
      process.env.NEXT_PUBLIC_SHOW_DEVELOPER_SETTINGS_CLICK_COUNT ?? 5,
    BASE_URL: process.env.NEXT_PUBLIC_DEMO_TOOLS_URL
  },
  ERROR_CODES: {
    CONNECTIVITY: 'CONNECTIVITY_ERROR',
    UNKNOWN: 'UNKNOWN'
  },
  VOICE_APP_URL: '/voice',
  ENABLE_VOICE_APP:
    (process.env.NEXT_PUBLIC_ENABLE_VOICE_APP as string) === 'true',
  ENABLE_XBRL_APP:
    (process.env.NEXT_PUBLIC_ENABLE_XBRL_APP as string) === 'true',
  ENABLE_SMS_APP: (process.env.NEXT_PUBLIC_ENABLE_SMS_APP as string) === 'true'
}
